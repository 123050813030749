function clearBasicViewItemActive() {
    var item = document.querySelector('.form-pict.show');
    if (item) {
        item.classList.remove('show');
    }

    var item = document.querySelector('.view-menu ul li a.active');
    if (item) {
        item.classList.remove('active');
    }
}

function changeBasicViewItem(item, manual) {
    // if manual -> stop interval
    if (manual)
        indexBasics = -1;

    clearBasicViewItemActive();
    if (item) {
        item.classList.toggle('active');

        var img = document.querySelector(`#basics-${item.dataset['type']}`);
        img.classList.add('show');
    }
}
// expose the fn in global scope for inline use
window.changeBasicViewItem = changeBasicViewItem

var indexBasics = 1;

setInterval(() => {
    if (indexBasics >= 0 && document.querySelectorAll('.view-menu ul li a')) {
        var items = document.querySelectorAll('.view-menu ul li a');
        changeBasicViewItem(items[indexBasics]);

        indexBasics++;

        if (indexBasics >= items.length)
            indexBasics = 0;
    }
}, 5000);
